<template>
  <div>
    <keep-alive>
      <!-- 这里是会被缓存的视图组件 -->
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <!-- 这里是不被缓存的视图组件 -->
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
export default {
  name: "index",
  components: {},
  computed: {},
  watch: {},
  data () {
    return {};
  },
  mounted () { },
};
</script>
<style lang="less" scoped></style>
